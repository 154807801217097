import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

export default ({ data }) => {
  const post = data.markdownRemark;
  const driver = (post.frontmatter.link && post.frontmatter.linkLabel) ? `<a class="btn btn--lg" href="${post.frontmatter.link}">${post.frontmatter.linkLabel}</a>` : '';
  return (
    <Layout>
      <div className="project">
        <img src={`../${post.frontmatter.logo}`} alt={post.frontmatter.title} className="mb-8 block" />
        <h1 className="h2">{post.frontmatter.title}</h1>
        <p><strong>Tech: </strong>{post.frontmatter.tags}</p>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <p dangerouslySetInnerHTML={{ __html: driver }} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        logo
        tags
        link
        linkLabel
      }
    }
  }
`
